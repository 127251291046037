.chat-window {
  display: grid;
  grid-template-areas: 'candidate-chat candidate-chat';
  border: 1px solid #dadbdd;
  height: 100vh;
  width: 70%;
  margin: auto;
  overflow: hidden;

  > .chat-window-section {
    border: 1px solid #dadbdd;
  }
  .hide-suggestions {
    visibility: hidden;
  }
  .candidate-chat-section {
    grid-area: candidate-chat;
    display: flex;
    flex-direction: column;
    height: 100vh;
    --left-padding: 100px;

    .chat-header {
      display: flex;
      padding: 30px;
      flex-shrink: 0;
      padding-left: var(--left-padding);
      width: 100%;
      border-bottom: 1px solid #dadbdd;

      .powered-by {
        margin-left: auto;
        display: flex;
        align-items: baseline;
      }
    }

    .messages-wrapper {
      flex-grow: 1;
      padding: 30px;
      padding-left: var(--left-padding);
      background-color: #fafafa;
    }

    .bot-suggestion-content {
      display: flex;
      justify-content: flex-end;

      .slot-select {
        margin-left: 6px;
        width: 140px;
        margin-right: 6px;
      }
      .date-select {
        width: 140px;
      }
    }

    .chat-input {
      padding: 10px;
      display: flex;
      flex-shrink: 0;
      background-color: #ffffff;
      padding-right: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: var(--left-padding);

      textarea {
        border: 0px;

        &.ant-input:focus {
          box-shadow: none;
        }
      }

      .chat-send-button {
        margin-left: 10px;
      }
    }
  }

  .recruiter-section {
    grid-area: recruiter-info;
  }
}

.typing-indicator-block {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing-indicator-container .typing-indicator-dot {
  background-color: #90949c;
}

.typing-indicator-dot {
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin-right: 3px;
  margin-left: 3px;
  width: 4px;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }
}

.typing-indicator-dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing-indicator-dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing-indicator-dot:nth-child(3) {
  animation-delay: 400ms;
}

.bot-favicon {
  height: 60px;
  width: 60px;
}

.bot-title {
  height: 16px;
  color: #7f8fa5;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-left: 10px;
}

.bot-title-image {
  display: flex;
  align-items: baseline;
}

.bot-powered-by {
  height: 15px;
  color: #7f8fa5;
  font-size: 13px;
  font-style: italic;
  line-height: 15px;
  margin-right: 10px;
}
.default-icon {
  height: 35px;
  width: 35px;
}
@media only screen and (max-width: 768px) {
  .chat-window{
    width: 100%;
  }

  .chat-input{
    padding: 10px  !important;
  }

  .messages-wrapper
  {
    padding: 8px  !important;
  }
  .chat-header{ padding: 10px !important; }
}
