@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

*,
*::after,
*::before {
    outline: none;
    box-sizing: border-box;
}

:root {
    --app-width: 90%;
    --app-max-width: 1366px;
    --navbar-height: 64px;
    --body-content-padding: 50px;
    --primary-color: #13c26b;
    --secondary-color: #4293F4;
}


html,
body {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
}
