.unsubscribeOptionContainer {
  width: 990px !important;
  border: 1px solid #cfd5db;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px 28px;
  margin: 120px auto !important;
  background: #fff;
  .title {
    h2 {
      font-size: 24px;
      color: #1f2730;
      font-weight: 400;
    }
  }
  .lineSeperator {
    width: 986px;
    margin-left: -28px;
    border: 1px solid #dadbdd87;
  }

  .unsubscribeContent {
    color: #000000;
    margin: 24px 38px;
    .location {
      font-weight: 500;
    }
    p {
      margin-bottom: 0.2em;
      font-weight: 500;
    }
  }

  .subscribeOptions {
    display: flex;
    gap: 2em;
    align-items: center;
    margin-left: 36px;
    width: 843px;

    .unsubscribeRadioGroup {
      display: flex;
      flex-direction: row;
      gap: 2em;
      justify-content: center;
      flex-grow: 2;
      color: #1f2730;
      label {
        max-width: 408px;
        height: 97px;
        border: 1px solid #dadbdd;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        font-weight: 400;
        align-items: center;
        flex-grow: 2;
      }
      .unsubscribeAll::before {
        width: 0px;
      }
    }

    :global {
      .ant-radio-group {
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          border: 1px solid #13c26b;
          color: #1f2730;
          box-shadow: none;
          background: #f6ffed;
          font-weight: bold;
        }
        .ant-radio-wrapper-checked {
          border: 1px solid #13c26b;
          color: #1f2730;
          box-shadow: none;
          background: #f6ffed;
          font-weight: bold;
        }
      }
    }
  }
  .actionButtons {
    justify-content: right;
    margin-top: 40px;
    display: flex;
    button {
      border: none;
      background: transparent;
      box-shadow: none;
      font-weight: bold;
    }
    .confirm {
      width: 111px;
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      padding: 3px 1px;
      height: 34px;
      margin-right: 48px;
    }

    .selected {
      background-color: #13c26b;
      color: white;
    }

    .notSelected {
      background-color: rgba(0, 0, 0, 0.25);
      color: black;
      border-color: #d9d9d9;
    }
  }
}

.unsubscribeSuccess {
  height: 100vh;
  display: grid;
  place-content: center;
  text-align: center;

  h2 {
    margin-bottom: 0;
    font-size: 30px;
    letter-spacing: 0.44px;
    color: rgba(7, 16, 26, 0.9);
  }
  p {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    margin: auto;
    max-width: 620px;
  }
}
