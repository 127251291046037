.filter-suggestions {
  text-align: right;
  right: 100px;
  position: relative;
  animation: fadeInUp 0.6s;
}

.filter-suggestion {
  border: 1px solid #4293f4;
  margin: 0px 10px;
  border-radius: 17px;
  padding: 0px 26px;

  &.No, &.No:hover, &.No:active{
    color: #4293f4;
  background: #fff;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
