.connect-message-window-flex-items {
  width: 100%;
}

.messages-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  padding-right: 5px;
  flex-direction: column-reverse;

  .inner-messages-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .chat-window-body-infinite-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
  }
}

.connect-message-bubble-wrapper {
  max-width: 70%;
  margin-bottom: 10px;

  &.right {
    align-self: flex-end;
  }

  &.left {
    align-self: flex-start;
  }
}

.connect-message-bubble {
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 14px;
  word-break: break-word;

  &.right {
    background-color: var(--secondary-color);
    color: #ffffff;
    border-radius: 14px 14px 0 14px;

    &.Pending {
      opacity: 0.5;
    }

    &.Success {
      opacity: 1;
    }

    > a {
      color: white;
      text-decoration: underline;
    }
  }

  &.left {
    background-color: #ffffff;
    border-radius: 0 14px 14px 14px;
    color: rgba(7, 16, 26, 0.7);
    border: 1px solid #dadbdd;
  }
}

.connect-message-bubble-time {
  margin-left: 10px;
  font-size: 12px;

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }
}
