.arya-logo {
  text-align: center;

  .arya-logo-symbol {
    height: 50px;
    width: 50px;
  }

  .arya-logo-text {
    height: 42px;
    width: 100px;
  }
}
