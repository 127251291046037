.email-verification-wrapper {
  margin: 15% 15%;
  margin-top: 10%;

  .email-verification {
    display: flex;
    flex-direction: row;
    padding: 20px 20px;
    border: 2px solid black;
    border: 1px solid #dadbdd;
    border-radius: 10px;
    background-color: #ffffff;

    .email-verification-logo {
      display: flex;
      align-items: center;
      padding: 20px;
    }

    .email-verification-text-content {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-top: auto;
      margin-bottom: auto;

      .email-verification-text-content-title {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
      }

      .email-verification-text-content-message {
        color: rgba(7, 16, 26, 0.7);
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
      }

      .email-verification-text-content-button {
        width: 120px;
        background-color: #13c26b;
        border-color: #13c26b;
      }
    }
  }
}

.email-verification-icon-success {
  color: #13c26b;
  margin-right: 15px;
  font-size: 24px;
}

.email-verification-icon-failed {
  color: #f5222d;
  margin-right: 15px;
  font-size: 24px;
}

.email-verification-text-success {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.email-verification-text-failed {
  color: #f5222d;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
