.unsubscribe-div-wrapper {
  display: flex;
}

.unsubscribed {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-48%, -50%);
  .ant-divider-horizontal {
    display: block;
    clear: both;
    width: 49% !important;
    min-width: 0%;
    height: 1px;
    margin: 24px 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.unsubscribed-missed {
  display: flex;
  justify-content: center;
  color: rgba(7, 16, 26, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 31px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 40px;
}

.unsubscribed-message {
  color: rgba(7, 16, 26, 0.7);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: auto;
  max-width: 800px;
}

.unsubscribed-confirmation {
  color: rgba(7, 16, 26, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.unsubscribed-button {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  border-radius: 20px;
}

.unsubscribed-icon {
  height: 40px;
  width: 40px;
}
.unsubscribed-oval {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  height: 80px;
  width: 80px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(7, 16, 26, 0.2);
  margin-bottom: 15px;
}

.unsubscribed-button-wrapper {
  justify-content: center;
  display: flex;
  margin-top: 15px;
}
