.question-suggestions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  animation: fadeInUp 0.6s;
}

.question-wrapper {
  background: #fff;
  border: 1px solid #4293f4;
  margin: 0px 8px;
  border-radius: 17px;
  color: #4293f4;
  margin-bottom: 10px;
  padding: 8px 26px;
  cursor: pointer;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
